import React from "react";

const RadioGroup = ({ label, name, options, register }) => {
  return (
    <div>
      <div style={{ display: "flex", gap: "1rem" }}>
        <div>{label}:</div>
        {options.map((option, index) => (
          <div key={index}>
            <input
              type="radio"
              id={`${name}-${index}`}
              name={name}
              value={option.value}
              {...register(name)}
            />
            <label htmlFor={`${name}-${index}`}>{option.label}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RadioGroup;
