import React, { useState, useEffect } from "react";
import "./../Style.css";
import { user_Login } from "../../Redux/Auth/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { NotificationContainer } from "react-notifications";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import logo from "./../Fixaligner_logo.svg";
const Login = (props) => {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [isclicked, setIsclicked] = useState(false);
  const dispatch = useDispatch();
  const { error, login } = useSelector((state) => state.Auth);

  const createNotification = (type, title, message) => {
    switch (type) {
      case "info":
        NotificationManager.info(message);
        break;
      case "success":
        NotificationManager.success(title, message);
        break;
      case "warning":
        NotificationManager.warning(title, message, 3000);
        break;
      case "error":
        NotificationManager.error(title, message, 5000, () => { });
        break;
      default:
        break;
    }
  };


  const Login = (e) => {
    e.preventDefault();
    dispatch(user_Login(email, pass));
  };


  useEffect(() => {
    if (error !== null && login.length === 0) {
      createNotification(
        "error",
        "Check Your Credentials",
        "Invalid Credentials!"
      );
    }
  }, [login, error]);

  return (
    <div className="login-container">
      <NotificationContainer />
      <div className="auth-form-container">
        <div>
          <img src={logo} alt="logo" style={{ width: "10rem" }} />
        </div>
        <form className="login-form" >
          <label htmlFor="email">email</label>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Email"
            id="email"
            name="email"
          />
          <label htmlFor="password">password</label>
          <input
            value={pass}
            onChange={(e) => setPass(e.target.value)}
            type="password"
            placeholder="Password"
            id="password"
            name="password"
          />
          <div className="centerdiv">
            {" "}
            <button type="submit" onClick={Login} className="loginbutton">
              Sign In
            </button>
          </div>
        </form>
        <button className="link-btn">
          Foget Password ? Contact the  Adminstrator
        </button>
      </div>
    </div>
  );
};
export default Login;
