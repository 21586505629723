import axios from "axios";
import { PatientAction } from "./PatientReducers";
import { formatDate } from "@fullcalendar/core";
import moment from "moment";
export const getPatient = () => async (dispatch) => {
  const token = localStorage.getItem("jwtToken");

  try {
    dispatch(PatientAction.PatientRequest());

    const res = await axios.get(process.env.REACT_APP_API + "/api/patients", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch(PatientAction.PatientSuccess(res));
  } catch (error) {
    dispatch(PatientAction.PatientFail(error));
  }
};
export const AddPatient = (email, phone_number, district_id, plan_id, patients, preferedDate) => async (dispatch) => {
  dispatch(PatientAction.addPatientRequest());
  const token = localStorage.getItem("jwtToken");
  const role = localStorage.getItem("role");
  const apirequest = role == "clinic" ? "/api/create-patient" : "/api/take-appointment";
  try {
    await axios.post(
      process.env.REACT_APP_API + apirequest,
      {
        "info": {
          "email": email,
          "phone_number": phone_number,
          "district_id": district_id,
          "plan_id": plan_id,
          "preferred_date": preferedDate


        },
        "patients": patients
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(PatientAction.addPatientSuccess("success"));
    dispatch(getPatient());
  } catch (e) {
    dispatch(PatientAction.addPatientFail(e.response.data));
  }
};

export const updatePatient = (
  id,
  first_name,
  last_name,
  age,
  gender,
  teeth_model,
  number_of_aligners,
  current_aligner,
  timer_start_date,
  treatment_ended,
  payment_amount,
  status_id,
  stage_id,
  clinic_id,
  designer_id,
  scanner_id,
  printer_id,
  remaining_amount,
  treatmentEnded,
  confirm

) => async (dispatch) => {
  dispatch(PatientAction.updatePatientRequest());
  const token = localStorage.getItem("jwtToken");
  try {
    await axios.put(
      `${process.env.REACT_APP_API}/api/patients/${id}`,
      {
        first_name,
        last_name,
        age,
        gender,
        teeth_model,
        number_of_aligners,
        current_aligner,
        timer_start_date,
        treatment_ended,
        payment_amount,
        status_id,
        stage_id,
        clinic_id,
        designer_id,
        scanner_id,
        printer_id,
        remaining_amount,
        treatmentEnded,
        confirm
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(PatientAction.updatePatientSuccess());
    dispatch(getPatient());
  } catch (e) {
    dispatch(PatientAction.updatePatientFail(e.response.data));
  }
};



export const updateConfirmOfTreatmentPlan = (
  id,
  confirm,
  getpatient
) => async (dispatch) => {
  dispatch(PatientAction.editConfirmRequest());
  const token = localStorage.getItem("jwtToken");
  try {
    await axios.put(
      `${process.env.REACT_APP_API}/api/patients/${id}`,
      {
        confirm
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(PatientAction.editConfirmSuccess());
    if(getpatient){
      dispatch(getPatient());
    }
  } catch (e) {
    dispatch(PatientAction.editConfirmFail(e.response.data));
  }
};
export const deletePatient = (id) => async (dispatch) => {
  dispatch(PatientAction.deletePatientRequest());
  const token = localStorage.getItem("jwtToken");
  try {

    await axios.delete(process.env.REACT_APP_API + "/api/patients/" + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(PatientAction.deletePatientSuccess());
    dispatch(getPatient());
  } catch (e) {
    dispatch(PatientAction.deletePatientFail(e.response.data));
  }
};
export const getPatientStatistic = (id) => async (dispatch) => {
  dispatch(PatientAction.patientStatisticRequest());
  const token = localStorage.getItem("jwtToken");
  try {

    const res = await axios.get(process.env.REACT_APP_API + "/api/stats/" + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(PatientAction.patientStatisticSuccess(res));
  } catch (e) {
    dispatch(PatientAction.patientStatisticFail(e.response.data));
  }
};
export const getPatientFile = (id) => async (dispatch) => {
  const token = localStorage.getItem("jwtToken");

  try {
    dispatch(PatientAction.getPatientFileRequest());

    const res = await axios.get(process.env.REACT_APP_API + "/api/patients/files/" + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch(PatientAction.getPatientFileSuccess(res));
  } catch (error) {
    dispatch(PatientAction.getpatientFileFail(error));
  }
};



export const AddFiles = (id, files) => async (dispatch) => {
  const formdata = new FormData();
  for (let i = 0; i < files.length; i++) {
    formdata.append("files[]", files[i]);
  }
  dispatch(PatientAction.addFileRequest());
  const token = localStorage.getItem("jwtToken");
  try {
    await axios.post(
      process.env.REACT_APP_API + "/api/patients/files/" + id, formdata,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(PatientAction.addFileSuccess());
    dispatch(getPatientFile(id));
  } catch (e) {
    dispatch(PatientAction.addFileSuccess(e.response.data));
  }
};


export const AddImage = (id, images) => async (dispatch) => {
  const formdata = new FormData();
  for (let i = 0; i < images.length; i++) {
    formdata.append("images[]", images[i]);
  }
  dispatch(PatientAction.addImageRequest());
  const token = localStorage.getItem("jwtToken");
  try {
    await axios.post(
      process.env.REACT_APP_API + "/api/patients/images/" + id, formdata,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(PatientAction.addImageSuccess());
    dispatch(getPatientFile(id));
  } catch (e) {
    dispatch(PatientAction.addImageSuccess(e.response.data));
  }
}
export const deletePatientFile = (id, filename) => async (dispatch) => {
  dispatch(PatientAction.deletePatientRequest());
  const token = localStorage.getItem("jwtToken");

  try {

    await axios.delete(process.env.REACT_APP_API + "/api/patients/files/" + id + "/" + filename, {


      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(PatientAction.deletePatientSuccess());
    dispatch(getPatientFile(id));
  } catch (e) {
    dispatch(PatientAction.deletePatientFail(e.response.data));
  }
};


export const getpatientbyid = (id) => async (dispatch) => {
  const token = localStorage.getItem("jwtToken");

  try {
    dispatch(PatientAction.patientidRequest());

    const res = await axios.get(process.env.REACT_APP_API + "/api/patients/" + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch(PatientAction.patientidSuccess(res));
  } catch (error) {
    dispatch(PatientAction.patientidFail(error));
  }
}
export const getCalendardaily = (id) => async (dispatch) => {
  const token = localStorage.getItem("jwtToken");
  try {
    dispatch(PatientAction.calendarRequest());

    const res = await axios.get(process.env.REACT_APP_API + "/api/daily-check/" + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch(PatientAction.calendarSuccess(res));
  } catch (error) {
    dispatch(PatientAction.calendarFail(error));
  }
};

export const getDateCalendar = (id, date) => async (dispatch) => {
  const token = localStorage.getItem("jwtToken");
  try {
    dispatch(PatientAction.dateincalendarRequest());

    const res = await axios.post(
      process.env.REACT_APP_API + "/api/aligner-sessions-by-date/" + id,
      {
        date,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(PatientAction.dateincalendarSuccess(res));
  } catch (error) {
    dispatch(PatientAction.dateincalendarFail(error));
  }
};
