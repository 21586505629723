import BookOnlineIcon from "@mui/icons-material/BookOnline";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { Menu, MenuItem, ProSidebar } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import "./../../scenes/Style.css";
// import ChecklistIcon from "@mui/icons-material/Checklist";
import AirlineSeatReclineExtraIcon from "@mui/icons-material/AirlineSeatReclineExtra";
import DomainIcon from "@mui/icons-material/Domain";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import QuizIcon from "@mui/icons-material/Quiz";
import ReorderIcon from "@mui/icons-material/Reorder";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import logo from "./../../scenes/Fixaligner_logo.svg";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  return (
    <Box
      className="custom-box"
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
          zIndex: "0",
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
          zIndex: "0",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
              <img src={logo} alt="logo" style={{ width: "10rem" }} />


              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h4"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {localStorage.getItem("email")}
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  {localStorage.getItem("role")}
                </Typography>
              </Box>
            </Box>
          )}
          {(localStorage.getItem("role") == "admin" || localStorage.getItem("role") == "superadmin") && (
            <Box paddingLeft={isCollapsed ? undefined : "10%"}>
              <Item
                title="Dashboard"
                to="/"
                icon={<HomeOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Calendar"
                to="/calendar/0"
                icon={<CalendarTodayOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
 <Item
                title="Appointment"
                to="/Appointment"
                icon={<BookOnlineIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
              >
                Data
              </Typography>
              <Item
                title="Manage Team"
                to="/team"
                icon={<PeopleOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Patients"
                to="/Patient"
                icon={<AirlineSeatReclineExtraIcon />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title="Clinics"
                to="/Clinic"
                icon={<DocumentScannerIcon  />}
                selected={selected}
                setSelected={setSelected}
              />
    <Item
                title="Scanners"
                to="/Scanner"
                icon={<DomainIcon />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title="Lab"
                to="/Lab"
                icon={<PrecisionManufacturingIcon />}
                selected={selected}
                setSelected={setSelected}
              />
               <Item
                title="Printers"
                to="/Printer"
                icon={<LocalPrintshopIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            
           
 <Item
                title="Designer"
                to="/Designer"
                icon={<DesignServicesIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            
              <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
              >

                Mobile Application
              </Typography>
              <Item
                title="Plans"
                to="/Plan"
                icon={<ReorderIcon />}
                selected={selected}
                setSelected={setSelected}
              />
   <Item
                title="Districts"
                to="/District"
                icon={<EditLocationIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Faqs"
                to="/Faqapp"
                icon={<QuizIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Contact us"
                to="/Contactus"
                icon={<PeopleOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            </Box>
          )}
          {localStorage.getItem("role") == "clinic" && (
            <Box paddingLeft={isCollapsed ? undefined : "10%"}>
              <Item
                title="Dashboard"
                to="/"
                icon={<HomeOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Calendar"
                to={"/calendar/" + localStorage.getItem("id")}
                icon={<CalendarTodayOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
 <Item
                title="Appointment"
                to="/Appointment"
                icon={<BookOnlineIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Patient"
                to="/Patient"
                icon={<AirlineSeatReclineExtraIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            </Box>
          )}
            {localStorage.getItem("role") == "lab" && (
            <Box paddingLeft={isCollapsed ? undefined : "10%"}>
              

              <Item
                title="Patient"
                to="/Patient"
                icon={<AirlineSeatReclineExtraIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            </Box>
          )}
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
